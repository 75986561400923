<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos Usuario"
      ref="pageForm"
      :items="items">
      <template>
        <PageForm :typeform="typeform" @closeModa="closeModa" />
      </template>
    </PageHeader>
    <GameModalMenu
      ref="gameModalMenu"
      @openListGame="openGames"
      :model="currentPlayer" />
    <CloseSessionWheel
      ref="closeSessionWheel"
      :wheels="wheels"
      :player="currentPlayer" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <Tooltip>
                <ol>
                  <li>
                    Status: puedes inhabilitar la jugabilidad del jugador en las
                    salas.
                  </li>
                  <li>
                    Reportes: redirección al profit del jugador con el casino.
                    Podras notar el total de apuestas, ganancias para el jugador
                    y ganancias para el casino por parte del jugador en cuestion
                    <ul>
                      <li>
                        En este reporte puedes dirigirte a las transacciones del
                        jugador dandole click al "USER ID"
                      </li>
                    </ul>
                  </li>
                </ol>
              </Tooltip>
            </div>
            <div class="row mt-4 align-items-center">
              <div class="col-1">
                <span>{{ $t("filter.operator") }}: </span>
              </div>
              <multiselect
                :options="operators"
                v-model="operator"
                class="w-25"
                label="name"
                placeholder="Selecciona un operador"
                selectLabel=""
                deselectLabel="Click para deseleccionar"
                @input="handleSelection"></multiselect>
              <div class="col-1 d-flex justify-content-start">
                <b-button
                  class="uil-filter-slash"
                  variant="primary"
                  v-b-tooltip.hover
                  title="Tooltip content"
                  @click="cancelFilter"
                  :disabled="
                    Object.entries(filterData).length ? false : true
                  "></b-button>
              </div>
            </div>
            <div class="row mt-4 align-items-center">
              <div class="col-sm-12 col-md-6 d-flex justify-content-start">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center m-0">
                    {{ $t("filter.show") }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t("filter.entries") }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end d-flex">
                  <vs-tooltip success>
                    <vs-input
                      v-model="filter"
                      primary
                      type="search"
                      placeholder="USER ID, USERNAME"
                      class="shadow-lg"></vs-input>
                    <template #tooltip>
                      Busca en la pagina actual. Presiona el botón para buscar
                      en otras paginas.
                    </template>
                  </vs-tooltip>
                  <vs-button type="button" @click="handleSearch">{{
                    $t("filter.search")
                  }}</vs-button>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0">
                <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  v-if="tableData.length">
                  <template #cell(board)="data">
                    {{
                      data.item.isPhysic
                        ? data.item.board
                          ? "BOARD"
                          : "VIDEO"
                        : "MOBILE / DESKTOP"
                    }}
                  </template>
                  <template #cell(isAdmin)="data">
                    <vs-tooltip top primary>
                      <span>{{
                        data.item.isAdmin ? "ADMIN" : "NO ADMIN"
                      }}</span>
                      <template #tooltip>
                        {{
                          data.item.isAdmin
                            ? "NO SE BLOQUEARÁ LA PANTALLA POR INACTIVIDAD"
                            : "BLOQUEO DE PANTALLA POR INACTIVIDAD"
                        }}
                      </template>
                    </vs-tooltip>
                  </template>
                  <template #cell(isPhysic)="data">
                    <vs-tooltip top primary>
                      <span>{{
                        data.item.isPhysic ? "FISICO" : "NO FISICO"
                      }}</span>
                      <template #tooltip>
                        {{
                          data.item.isPhysic
                            ? "JUGADOR A TRAVES DE EQUIPO FISICO"
                            : "JUGADOR CON ACCESO POR PC Y MOVILES"
                        }}
                      </template>
                    </vs-tooltip>
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      class="position-absolute">
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>
                      <div v-if="userRole === 'ADMIN'">
                        <b-dropdown-item @click="changeEstatus(data.item)">
                          <i class="mdi mdi-check-outline m-2 font-size-18" />
                          <span class="align-middle ml-5">
                            {{ $t("players.status") }}</span
                          >
                        </b-dropdown-item>
                      </div>
                      <b-dropdown-item @click="gotToReports(data)">
                        <i class="uil-file-alt m-2 font-size-18" />
                        <span class="align-middle ml-5">{{
                          $t("sidebar.reports.text")
                        }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="playerAdmin(data)">
                        <i class="uil-user m-2 font-size-18" />
                        <span class="align-middle ml-5">Player admin</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="playerPhysic(data)">
                        <i class="uil-user m-2 font-size-18" />
                        <span class="align-middle ml-5">Player Fisico</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="closeSession(data.item)">
                        <i class="uil-user m-2 font-size-18" />
                        <span class="align-middle ml-5">CERRAR SESION</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
                <div v-if="!tableData.length">
                  <p>{{ $t("tableReports.noResults") }}</p>
                </div>
              </div>
            </b-overlay>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"></button>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="tableData.length < limit"></button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import PageForm from "./form";
import Swal from "sweetalert2";
import { breadCrumbData } from "../../../helpers/breadcrumbs/breadCrumbs";
import { setUserAction } from "../../../helpers/userActions/userActions";
import Multiselect from "vue-multiselect";
import Tooltip from "../../../components/tooltip.vue";
import { mapActions, mapGetters } from "vuex";
import GameModalMenu from "../../../components/GameModalMenu.vue";
import CloseSessionWheel from "./components/CloseSessionWheel.vue";

/**
 * Users-table component
 */
export default {
  page: {
    title: "Player Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    PageForm,
    Multiselect,
    Tooltip,
    GameModalMenu,
    CloseSessionWheel,
  },
  data() {
    return {
      loading: false,

      skip: 0,
      limit: 10,

      tableData: [],
      title: this.$t("sidebar.players"),
      items: breadCrumbData.players,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["userId", "username"],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "username",
          sortable: true,
          label: this.$t("players.name").toUpperCase(),
        },
        {
          key: "userId",
          sortable: true,
          label: this.$t("players.userId").toUpperCase(),
        },
        {
          key: "status",
          sortable: true,
          label: this.$t("players.status").toUpperCase(),
          formatter: (value) => {
            return value ? this.$t("helpers.isActive") : "Bloqueado";
          },
          class: "text-center",
        },
        {
          key: "isAdmin",
          sortable: true,
          label: this.$t("players.isAmin").toUpperCase(),
          class: "text-center",
        },
        {
          key: "isPhysic",
          sortable: true,
          label: this.$t("players.isPhysic").toUpperCase(),
          class: "text-center",
        },
        {
          key: "board",
          sortable: true,
          label: "ROLE",
          class: "text-center",
        },
        {
          key: "actions",
          sortable: false,
          label: this.$t("actions.text").toUpperCase(),
        },
      ],
      userSave: {},

      typeform: {
        name: "",
        lastName: "",
        userName: "",
        password: "",
        email: "",
        client: "",
      },
      userRole: "",
      userData: "",
      filterData: {},
      operator: {},
      operators: [],
      currentPlayer: {},
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    ...mapGetters({
      mapOperators: "operators/getOperators",
      playerSuccess: "players/getSuccess",
      mapPlayers: "players/getPlayers",
      envs: "notification/mapEnvs",
      getErrors: "notification/getErrors",
      wheels: "wheel/getWheels",
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getData();
    this.getOperator();
    this.getUserRole();
  },
  methods: {
    async handleSearch() {
      this.loading = true;
      try {
        const { data } = await this.$http.get("/player-round/find-by-term", {
          params: {
            term: this.filter,
          },
        });

        this.tableData = data.players;
      } catch (error) {
        console.log("ERROR HANDLE SEARCH", error);
        this.tableData = [];
      }
      this.loading = false;
    },
    cancelFilter() {
      this.operator = {};
      this.filterData = {};
      this.getData();
    },
    handleSelection(value) {
      if (!value) {
        this.filterData = {};
      } else {
        const operatorId = value._id;
        this.filterData = { operatorId };
      }
      this.getData();
    },
    nextPage() {
      this.skip += this.limit;
      this.currentPage++;
      this.getData();
    },
    beforePage() {
      this.skip -= this.limit;
      this.currentPage--;
      this.getData();
    },
    getUserRole() {
      this.userRole = this.$store.getters["user/getRole"];
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getOperator() {
      try {
        await this.fetchOperators({ options: {} });
        this.operators = this.mapOperators;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      this.loading = true;

      try {
        const options = {
          params: {
            ...this.filterData,
            page: this.currentPage,
            limit: this.limit,
          },
        };

        await this.fetchPlayerRound({ options });

        if (!this.playerSuccess) {
          this.loading = false;
          return (this.tableData = []);
        }

        this.tableData = this.mapPlayers;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: "",
        lastName: "",
        userName: "",
        password: "",
        email: "",
        client: "",
      };
      this.getData();
    },
    edit(item) {
      const userSave = item;
      this.typeform = userSave;
      if (item.client) this.typeform.client = item.client._id;
      this.$refs.pageForm.modal = true;
    },
    changeEstatus(item) {
      let text = "";
      let status = "";
      if (item.status == true) {
        text = "bloquear";
      } else {
        text = "desbloquear";
      }

      Swal.fire({
        title: "Esta de acuerdo de " + text + " el jugador?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (item.status == true) {
            status = false;
          } else {
            status = true;
          }
          this.$http
            .put("/player-round/updatePlayer/", {
              id: item._id,
              status: status,
            })
            .then(({ data }) => {
              if (!data.ok) {
                return Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Error bloqueando al usuario",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }

              let schema = setUserAction(
                this.$route.name,
                "",
                `${text}: ${item.username} (${item._id})`
              );
              this.$store.commit("actions/setActionsMut", schema);

              Swal.fire({
                position: "center",
                icon: "success",
                title: "Usuario procesado con exito",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                return this.getData();
              });
            })
            .catch((err) => {
              console.log("Error cambiando el estatus del jugador", err);
            });
        } else if (result.isDenied) {
          Swal.fire(
            "Tiene oportunidad de verificar la información",
            "",
            "info"
          );
        }
      });
    },
    remove(id) {
      this.$http
        .delete(`users/${id}`)
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Usuario eliminado con exito",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gotToReports({ item }) {
      const playerId = item._id;
      this.$router.push({
        path: `/reports/players/${playerId}`,
        query: {
          username: item.username,
          isAdmin: item.isAdmin,
        },
      });
    },
    async playerAdmin({ item }) {
      const status = item.isAdmin;
      const isAdmin = !status;
      const msg = isAdmin ? "agregar" : "quitar";

      const { isConfirmed } = await Swal.fire({
        title: `¿Estas seguro de ${msg} este permiso al jugador?`,
        icon: "question",
        showDenyButton: true,
      });

      if (!isConfirmed) return;

      try {
        await this.changePlayerAdmin({ player: item, isAdmin });

        if (!this.playerSuccess)
          return Swal.fire("ERROR AGREGANDO PERMISOS DE ADMIN", "", "error");

        Swal.fire("Permisos agregados correctamente", "", "success");
        this.checkErrors();
      } catch (error) {
        console.log(error);
        Swal.fire("Talk to admin", "", "warning");
      }

      this.getData();
    },
    async playerPhysic({ item }) {
      const status = item.isPhysic;
      const isPhysic = !status;
      let board = false;
      const msg = isPhysic ? "agregar" : "quitar";

      const { isConfirmed } = await Swal.fire({
        title: `¿Estas seguro de ${msg} esta modalidad al jugador?`,
        icon: "question",
        showDenyButton: true,
      });

      if (!isConfirmed) return;

      if (isPhysic) {
        const { isConfirmed } = await Swal.fire({
          title: `Seleccione un rol, por favor:`,
          showDenyButton: true,
          denyButtonText: "VIDEO",
          confirmButtonText: "BOARD",
        });

        board = isConfirmed;
      }

      try {
        await this.changePlayerPhysic({
          player: item,
          isPhysic,
          board,
        });

        if (!this.playerSuccess)
          return Swal.fire("ERROR HACIENDO AL PLAYER FISICO", "", "error");

        Swal.fire("Jugador modificado correctamente", "", "success");
        this.checkErrors();
      } catch (error) {
        console.log(error);
        Swal.fire("Talk to admin", "", "warning");
      }

      this.getData();
    },
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: "auto",
      });
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`;
          this.openWinNotification(null, "warning", "ERROR", message);
        });
      }
    },
    closeSession(item) {
      this.currentPlayer = item;
      this.$refs.gameModalMenu.modal = true;
    },
    async openGames(data) {
      const { game } = data;

      switch (game.type) {
        case "wheelFortune":
          {
            await this.fetchWheels();
            this.$refs.closeSessionWheel.modal = true;
          }
          break;

        default:
          break;
      }
    },
    ...mapActions({
      fetchOperators: "operators/fetchOperators",
      fetchPlayerRound: "players/fetchPlayerRound",
      changePlayerAdmin: "players/changePlayerAdmin",
      changePlayerPhysic: "players/changePlayerPhysic",
      fetchWheels: "wheel/fetchWheels",
    }),
  },
  middleware: "authentication",
  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.getData();
    },
  },
};
</script>
