<template>
  <b-modal id="game-modal-menu" v-model="modal" size="md" hide-footer centered>
    <div v-for="item in wheels" :key="item.id" class="card my-2">
      <div class="d-flex align-items-center justify-content-between mx-2">
        <div>{{ item.name }}</div>
        <vs-button @click="closeSession(item.uuid)" :loading="loading"
          >CERRAR SESION</vs-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["wheels", "player"],
  data() {
    return {
      modal: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      success: "players/getSuccess",
    }),
  },
  methods: {
    async closeSession(gameUuid) {
      const { isConfirmed } = await Swal.fire({
        showCancelButton: true,
        title: "ESTAS SEGURO DE CERRAR LA SESION?",
      });

      if (!isConfirmed) return;

      await this.closeWheelSession({ player: this.player, gameUuid });

      if (!this.success)
        return Swal.fire({ title: "ERROR REMOVIENDO JUGADOR", icon: "error" });

      Swal.fire({ title: "JUGADOR REMOVIDO", icon: "success" });
    },
    ...mapActions({
      closeWheelSession: "players/closeWheelSession",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
